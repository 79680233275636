
.file-card .file-inputs input {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  background: #2587be;
  position: absolute;
  width: 60px;
}
.file-card .file-inputs button {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #25be58;
  font-size: 15px;
  cursor: pointer;
}
.file-card .file-inputs button i {
  color: #2587be;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}
.main {
  font-weight: bold;
}
.info {
  font-size: 0.8rem;
}
