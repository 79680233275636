/* Switch element */
.form-switch {
    @apply relative select-none;
    width: 60px;
}

.form-switch label {
    @apply block overflow-hidden cursor-pointer h-8 rounded-full;
}

.form-switch label>span:first-child {
    @apply absolute block rounded-full;
    width: 28px;
    height: 28px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all .15s ease-out;
}

.form-switch input[type="checkbox"]:checked+label {
    @apply bg-orange-600;
}

.form-switch input[type="checkbox"]:checked+label>span:first-child {
    left: 30px;
}