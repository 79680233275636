.small-div {
  flex: 0 1 auto;
}

.big-div {
  flex: 1 1 auto;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Adjust spacing as needed */
  justify-content: center; /* Center items horizontally */
  align-items: flex-end; /* Align items at the end of the container vertically */
}

.more-files {
  display: flex;
  align-items: center;
  font-size: 14px; /* Adjust as needed */
  color: #666; /* Adjust color as needed */
}
